<template>

  <!-- Dialog Add Startup Cost -->
  <div>
    <ib-dialog
      id="dialog-story-mode-add-startup-cost"
      empty-header
      :visible="dialogAddStartupCostVisible"
      :fullscreen="true"
      @open="openDialog"
      @close="closeDialog"
      @closed="closedDialog"
    >
      <!-- Mobile title -->
      <template #title>
        <ib-dialog-container class="d-md-none">
          <h2>
            {{ $tc('addStartupCost') }}
          </h2>
        </ib-dialog-container>
      </template>
      <!-- /Mobile title -->

      <!-- Content Wrapper -->
      <ib-dialog-container class="content-wrapper px-1" max-width="1920px">
        <!-- Header -->
        <add-dialog-header>
          <template #left>
            <!-- Title -->
            <h2 class="m-0">
              {{ $tc('addStartupCost') }}
            </h2>
            <!-- /Title -->
          </template>

        </add-dialog-header>
        <!-- /Header -->

        <!-- Info Guide -->
        <info-guide-add-dialog
          class="mb-3 d-block d-xl-none"
          :visible="guideVisible"
          :title="$t('pages.businessGuide.startUpCosts.addDialog.guide.title')"
          :text="$t('pages.businessGuide.startUpCosts.addDialog.guide.text')"
          @click="onToggleDialogGuide"
        />
        <!-- Info Guide -->

        <!-- Ai Buddy -->
        <ai-buddy
          v-if="$breakpoint.lgAndDown"
          type="startup-costs"
          class="ai-buddy-margin-bottom d-block d-xl-none"
          @suggestion="setSuggestion"
          @click="guideVisible = false"
        />
        <!-- Ai Buddy -->

        <!-- Inner Wrapper -->
        <div class="inner-wrapper">
          <div class="grid-wrapper">
            <el-row :gutter="20">

              <!-- Left Side -->
              <el-col :md="12" :xl="7">

                <!-- Form Wrapper -->
                <div class="startup-form-wrapper">

                  <!-- Form Inner -->
                  <div class="startup-form-inner-top">

                    <!-- Form -->
                    <el-form :model="form">

                      <!-- Name -->
                      <div class="d-flex align-items-center">
                        <ib-input
                          v-model="form.name"
                          class="flex-grow-1"
                          :label="$t('pages.businessGuide.startUpCosts.addDialog.fields.name')"
                        />
                        <ai-buddy-button-rounded
                          class="ai-buddy ml-2"
                          emit-event-buss-events
                          animation-key="roundedButtonAddModal"
                        />
                      </div>
                      <!-- /Name -->

                      <!-- Startup Cost Type -->
                      <el-form-item
                        id="sc-dropdown"
                        class="input-inside-label"
                        :label="$t('pages.businessGuide.startUpCosts.addDialog.fields.startupCostType')"
                        :class="{ 'focused' : focus.type === true }"
                      >

                        <!-- Startup Cost Select -->
                        <ib-select
                          ref="groupSelect"
                          v-model="form.typeNiceName"
                          :popper-append-to-body="false"
                          popper-class="promo-dropdown"
                          placeholder=" "
                          @blur="toggleFocus('type')"
                          @focus="toggleFocus('type')"
                          @change="changeType"
                        >

                          <!-- Startup Cost Groups -->
                          <div
                            v-for="group in options.groups"
                            :key="group.id"
                            class="group-wrapper"
                          >

                            <!-- Startup Cost Group -->
                            <div
                              class="group-item-wrapper"
                              :class="{
                                'selected': form.type === group.value,
                                'disabled': true
                              }"
                            >
                              <!--                              @click="changeTypeGroup(group.value)"-->

                              <!-- Startup Cost Group Image -->
                              <div class="round-image-wrapper">
                                <img class="startup-cost-image" :src="group.imgPath" svg-inline>
                              </div>
                              <!-- /Startup Cost Group Image -->

                              <!-- Startup Cost Group Label -->
                              <span>{{ group.label }}</span>
                              <!-- /Startup Cost Group Label -->

                            </div>
                            <!-- /Startup Cost Group -->

                            <!-- Startup Cost Group Items -->
                            <ib-option
                              v-for="item in group.items"
                              :key="item.value"
                              class="w-arrows"
                              :label="item.label"
                              :value="item.value"
                              :class="{ 'selected': item.value === form.type }"
                            />
                            <!--                              :disabled="isTypeDisabled(item.value, group.value)"-->
                            <!-- /Startup Cost Group Items -->

                          </div>
                          <!-- /Startup Cost Groups -->

                        </ib-select>
                        <!-- /Startup Cost Select -->

                      </el-form-item>
                      <!-- /Startup Cost Type -->

                      <!-- Amount -->
                      <el-form-item
                        class="currency-related-form-item"
                        :label="$t('pages.businessGuide.startUpCosts.addDialog.fields.amount')"
                      >
                        <span class="currency-symbol">{{ currencySymbol }}</span>
                        <div class="currency-related-input percentage el-input">
                          <currency-input
                            v-model="form.amount"
                            class="el-input__inner"
                            :distraction-free="false"
                            :currency="null"
                            :value-range="{min: 0, max: 999999999}"
                            :precision="{min: 0, max: 2}"
                            locale="en-US"
                          />
                        </div>
                      </el-form-item>
                      <!-- /Amount -->

                      <!-- One Time Or Installments -->
                      <form-switcher
                        v-model="form.oneTime"
                        :options="[{
                          title: $t('oneTime'),
                          value: true,
                          callback: changeToOneTime
                        },{
                          title: $t('installments'),
                          value: false,
                          callback: changeToInstallments
                        }]"
                      />
                      <!-- /One Time Or Installments -->

                      <!-- Startup Form Inner Bottom -->
                      <div class="startup-form-inner-bottom">

                        <!-- Nº of installments & Payment Method -->
                        <div class="input-group" :class="{ 'visible': form.oneTime === false }">

                          <!-- Nº of installments -->
                          <el-form-item
                            class="input-inside-label"
                            :label="$t('pages.businessGuide.startUpCosts.addDialog.fields.noOfInstallments')"
                            :class="{ 'focused': focus.numberOfInstallments === true }"
                          >
                            <div class="input-with-select el-input">
                              <currency-input
                                ref="installmentsInput"
                                v-model="form.numberOfInstallments"
                                class="el-input__inner"
                                :distraction-free="false"
                                :currency="null"
                                :value-range="{min: 1, max: maximumNumberOfInstallments()}"
                                :precision="0"
                                locale="en-US"
                                @focus="toggleFocus('numberOfInstallments')"
                                @blur="toggleFocus('numberOfInstallments')"
                              />
                            </div>
                          </el-form-item>
                          <!-- /Nº of installments -->

                          <!-- Payment Method -->
                          <el-form-item
                            class="input-inside-label"
                            :label="$t('pages.businessGuide.startUpCosts.addDialog.fields.paymentMethod.title')"
                            :class="{ 'focused' : focus.paymentMethod === true }"
                          >
                            <el-select
                              v-model="form.paymentMethod"
                              :filterable="false"
                              placeholder="  "
                              popper-class="promo-dropdown"
                              :popper-append-to-body="false"
                              @change="changePaymentMethod"
                            >
                              <ib-option
                                v-for="paymentMethod in options.paymentMethods"
                                :key="paymentMethod.key"
                                :label="paymentMethod.label"
                                :value="paymentMethod.value"
                              />
                            </el-select>
                          </el-form-item>
                          <!-- /Payment Method -->

                        </div>
                        <!-- /Nº of installments & Payment Method -->

                        <!-- When Will It Occur -->
                        <el-form-item
                          class="input-inside-label yellow"
                          :label="$t('pages.businessGuide.startUpCosts.addDialog.fields.whenWillItOccur')"
                          :class="{ 'focused': focus.whenWillItOccur === true }"
                        >
                          <el-date-picker
                            v-model="form.whenWillItOccur"
                            :clearable="false"
                            :editable="false"
                            :picker-options="pickerOptions"
                            :popper-append-to-body="false"
                            format="MMM, yyyy"
                            class="date-picker-monthly"
                            popper-class="date-picker-monthly-dropdown-modal"
                            type="month"
                            value-format="yyyy-MM-dd"
                            @blur="toggleFocus('whenWillItOccur')"
                            @change="changeWhenWillItOccur"
                            @focus="toggleFocus('whenWillItOccur')"
                          />
                        </el-form-item>
                        <!-- /When Will It Occur -->

                        <!-- Advanced Settings -->
                        <!-- <el-button class="advanced">-->
                        <!--   Advanced Settings <sup>SOON</sup>-->
                        <!-- </el-button>-->
                        <!-- /Advanced Settings -->

                      </div>
                      <!-- /Startup Form Inner Bottom -->

                    </el-form>
                    <!-- /Form -->

                  </div>
                  <!-- /Form Inner -->

                </div>
                <!-- /Form Wrapper -->

                <!-- Dialog Bottom -->
                <div class="my-4 my-md-0">

                  <!-- Divider -->
                  <ib-divider block class="my-4" />
                  <!-- /Divider -->

                  <el-row :gutter="10" class="d-flex">
                    <el-col :span="12">
                      <!-- Close -->
                      <ib-button class="w-100" variant="white" size="lg" font-family="regular" @click="closeDialogOnButton">
                        {{ $t('close') }}
                      </ib-button>
                      <!-- /Close -->
                    </el-col>

                    <el-col :span="12">
                      <ib-button
                        class="w-100 text-uppercase"
                        size="lg"
                        :disabled="!isAddEnabled"
                        :loading="loading"
                        @click="saveStartupCost"
                      >
                        {{ form.id === 0 ? $t('add') : $t('save') }}
                      </ib-button>
                    </el-col>
                  </el-row>
                </div>
                <!-- /Dialog Bottom -->

              </el-col>
              <!-- /Left Side -->

              <!-- Center -->
              <el-col :md="12" :xl="7">
                <template v-if="!$store.state.idea.storyMode.setUp.startupCosts.startupCosts.length">
                  <div class="d-flex flex-column justify-content-center align-items-center mt-5">
                    <img class="add-dialog-folder-icon" src="@/assets/img/icons/add-dialog-folder.svg" alt="">
                    <p class="add-dialog-folder-text font-outfit-regular">
                      {{ $t('createdItemsWillAppearHere') }}
                    </p>
                  </div>
                </template>

                <!-- Costs Added -->
                <div ref="cardHolder" class="costs-added">

                  <!-- Costs Added List -->
                  <div
                    v-for="startupCost in $store.state.idea.storyMode.setUp.startupCosts.startupCosts"
                    :key="startupCost.id"
                  >
                    <set-up-and-projections-card
                      class="mb-2"
                      :entity="startupCost"
                      :name="startupCost.name"
                      :date="getFormattedDate(startupCost.whenWillItOccur)"
                      :amount="getFormattedAmount(startupCost.amount)"
                      :frequency="startupCost.oneTime ? $t('oneTime') : $t('installments')"
                      :type="startupCost.typeGroup"
                      :edit-disabled="form.id === startupCost.id"
                      :delete-disabled="form.id === startupCost.id"
                      view="list"
                      @edit="onEditStartupCost"
                      @copy="onCopyStartupCost"
                      @delete="onDeleteStartupCost"
                    />
                    <!--                      :copy-disabled="startupCost.type === startupCost.typeGroup"-->
                  </div>
                  <!-- /Costs Added List -->
                  <erase-icon-button
                    v-if="removeButtonVisible"
                    confirm
                    :loading="removeLoadingExamples"
                    @confirm="deleteExamples"
                  />
                </div>
                <!-- /Costs Added -->

              </el-col>
              <!-- /Center -->

              <!-- Right Side -->
              <el-col :xl="10">
                <!-- Info Guide -->
                <info-guide-add-dialog
                  class="mb-3 d-none d-xl-block"
                  :visible="guideVisible"
                  :title="$t('pages.businessGuide.startUpCosts.addDialog.guide.title')"
                  :text="$t('pages.businessGuide.startUpCosts.addDialog.guide.text')"
                  @click="onToggleDialogGuide"
                />
                <!-- Info Guide -->

                <!-- Ai Buddy -->
                <div class="relative">
                  <ai-buddy
                    v-if="$breakpoint.lgAndUp"
                    type="startup-costs"
                    class="ai-buddy-margin-bottom d-none d-xl-block"
                    @suggestion="setSuggestion"
                    @click="handwritingSet('addStartupCostsSuggestion'); guideVisible = false"
                  />
                  <!-- Ai Buddy -->
                  <div v-if="getHandwritingVisible('addStartupCostsSuggestion')" class="d-none d-xl-block">
                    <AddDialogAiHandwriting />
                  </div>
                </div>
                <!-- Ai Buddy -->
              </el-col>
              <!-- /Right Side -->
            </el-row>
          </div>
        </div>
        <!-- /Inner Wrapper -->
      </ib-dialog-container>
      <!-- /Content Wrapper -->

    </ib-dialog>
  </div>
  <!-- /Dialog Add Startup Cost -->

</template>

<script>
import AddDialogAiHandwriting from '@/views/Home/Components/AddDialogAiHandwriting.vue'
import AddDialogHeader from '@/components/_v2/Common/AddDialogHeader'
import AiBuddyButtonRounded from '@/views/Home/StoryMode/Components/AiBuddyButtonRounded.vue'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import FormSwitcher from '@/components/_v2/Form/FormSwitcher'
import IbOption from '@/components/_v2/Select/IbOption'
import InfoGuideAddDialog from '@/views/Home/StoryMode/Components/Guide/InfoGuideAddDialog.vue'
import MixinCloseMenu from '@/mixins/closeDropdownMenuOnScroll'
import MixinCurrencies from '@/mixins/currencies'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinDialog from '@/mixins/dialog'
import MixinGamification from '@/mixins/gamification'
import MixinGuide from '@/mixins/guide'
import MixinHandwriting from '@/mixins/handwriting'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import SetUpAndProjectionsCard from '@/views/Home/StoryMode/Components/Cards/SetUpAndProjectionsCard'

import { mapActions, mapGetters } from 'vuex'
import AiBuddy from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddy.vue'

export default {
  name: 'DialogAddStartupCost',

  components: {
    AddDialogAiHandwriting,
    AiBuddy,
    AiBuddyButtonRounded,
    InfoGuideAddDialog,
    AddDialogHeader,
    EraseIconButton,
    FormSwitcher,
    IbOption,
    SetUpAndProjectionsCard
  },

  mixins: [
    MixinCloseMenu,
    MixinCurrencies,
    MixinDeleteIdeaExamples,
    MixinDialog,
    MixinGuide,
    MixinIdeaRoles,
    MixinGamification,
    MixinHandwriting
  ],

  props: {
    dialogAddStartupCostVisible: {
      type: Boolean,
      default: false
    },
    startupCostAction: {
      type: Object,
      default: null
    }
  },

  data () {
    const maxOccurDate = this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'Y').format('x')
    const minOccurDate = this.$moment(this.$store.state.idea.ideaSettings.startingFrom).format('x')

    return {
      loading: false,
      removeLoadingExamples: false,
      guideVisible: false,
      tableRowsToExpand: [],
      form: {
        id: 0,
        ideaId: this.$store.state.idea.id,
        name: '',
        type: '',
        typeNiceName: '',
        typeGroup: '',
        amount: null,
        oneTime: true,
        numberOfInstallments: null,
        paymentMethod: '',
        whenWillItOccur: this.$store.state.idea.ideaSettings.startingFrom
      },
      focus: {
        type: false,
        numberOfInstallments: false,
        paymentMethod: false,
        whenWillItOccur: false
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < minOccurDate || time.getTime() >= maxOccurDate
        }
      },
      options: {
        groups: [
          {
            id: 1,
            label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.facilities.title'),
            value: 'facilities',
            imgPath: require('@/assets/img/icons/set-up-facilities.svg'),
            items: [
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.facilities.landAndBuilding'), value: 'land-and-building' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.facilities.securityDeposit'), value: 'security-deposit' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.facilities.leaseholdImprovements'), value: 'leasehold-improvements' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.facilities.decorating'), value: 'decorating' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.facilities.furnitureAndFixtures'), value: 'furniture-and-fixtures' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.facilities.signage'), value: 'signage' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.facilities.other'), value: 'other-facilities' }
            ]
          },
          {
            id: 2,
            label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.equipment.title'),
            value: 'equipment',
            imgPath: require('@/assets/img/icons/set-up-equipment.svg'),
            items: [
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.equipment.specializedEquipment'), value: 'specialized-equipment' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.equipment.machinery'), value: 'machinery' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.equipment.vehicles'), value: 'vehicles' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.equipment.computersAndPhones'), value: 'computers-and-phones' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.equipment.posSystem'), value: 'pos-system' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.equipment.other'), value: 'other-equipment' }
            ]
          },
          {
            id: 3,
            label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.inventory.title'),
            value: 'inventory',
            imgPath: require('@/assets/img/icons/set-up-inventory.svg'),
            items: [
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.inventory.rawMaterials'), value: 'raw-materials' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.inventory.startingInventory'), value: 'starting-inventory' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.inventory.merchandise'), value: 'merchandise' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.inventory.officeAndStationery'), value: 'office-and-stationery' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.inventory.other'), value: 'other-inventory' }
            ]
          },
          {
            id: 4,
            label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.initialPromotion.title'),
            value: 'initial-promotion',
            imgPath: require('@/assets/img/icons/set-up-initial-promotion.svg'),
            items: [
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.initialPromotion.branding'), value: 'branding' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.initialPromotion.websiteDesign'), value: 'website-design' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.initialPromotion.advertisingMaterial'), value: 'advertising-material' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.initialPromotion.launchEvent'), value: 'launch-event' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.initialPromotion.marketResearch'), value: 'market-research' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.initialPromotion.other'), value: 'other-initial-promotion' }
            ]
          },
          {
            id: 5,
            label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.miscellaneous.title'),
            value: 'miscellaneous',
            imgPath: require('@/assets/img/icons/set-up-miscellaneous.svg'),
            items: [
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.miscellaneous.initialCashBalance'), value: 'initial-cash-balance' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.miscellaneous.trainings'), value: 'trainings' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.miscellaneous.rightsPatentsTrademarks'), value: 'rights-patents-trademarks' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.miscellaneous.licensesAndPermits'), value: 'licenses-and-permits' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.miscellaneous.franchiseFees'), value: 'franchise-fees' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.miscellaneous.legalAndProfessionalFees'), value: 'legal-and-professional-fees' },
              { label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.types.miscellaneous.other'), value: 'other-miscellaneous' }
            ]
          }
        ],
        paymentMethods: [
          {
            key: 1,
            label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.paymentMethod.paidMonthly'),
            value: 'monthly'
          },
          {
            key: 2,
            label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.paymentMethod.paidQuarterly'),
            value: 'quarterly'
          },
          {
            key: 3,
            label: this.$t('pages.businessGuide.startUpCosts.addDialog.fields.paymentMethod.paidAnnually'),
            value: 'annually'
          }
        ]
      }
    }
  },

  computed: {
    ...mapGetters('idea', ['getHasItemsFromTemplate']),

    isAddEnabled () {
      const enabled = this.form.name && this.form.type && this.form.typeGroup && this.form.amount && this.form.whenWillItOccur

      if (this.form.oneTime === false) {
        return enabled && this.form.numberOfInstallments && this.form.paymentMethod
      }

      return enabled
    },

    isDialogOpen () {
      return this.dialogAddStartupCostVisible
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getStartupCosts')
    }
  },

  methods: {
    ...mapActions('idea', [
      'deleteStoryModeStartupCosts',
      'deleteIdeaExamples'
    ]),

    setSuggestion (suggestion) {
      this.form.name = suggestion.name
      this.form.amount = suggestion.amount
      this.changeType(suggestion.startup_cost_type)
      this.form.type = suggestion.startup_cost_type
      this.form.oneTime = true
    },

    closeDialogOnButton () {
      this.$emit('close-dialog-on-button')
    },

    closeDialog () {
      this.$emit('close-dialog-add-startup-cost')
    },

    closedDialog () {
      this.resetForm()
      this.guideVisible = false
    },

    openDialog () {
      this.openGuideDialog('addStartupCostGuide')
      if (this.startupCostAction) {
        this.copyStartupCostToForm(this.startupCostAction)
        this.form.ideaId = this.$store.state.idea.id
      }
      this.tableRowsToExpand = []
      this.checkInputLabels()
    },

    onToggleDialogGuide () {
      this.guideVisible = !this.guideVisible
    },

    toggleFocus (input) {
      this.focus[input] = !this.focus[input]

      if (this.form[input] !== null && this.form[input] !== '') {
        this.focus[input] = true
      }
    },

    resetForm () {
      this.form = {
        id: 0,
        ideaId: this.$store.state.idea.id,
        name: '',
        type: '',
        typeNiceName: '',
        typeGroup: '',
        amount: null,
        oneTime: true,
        numberOfInstallments: null,
        paymentMethod: '',
        whenWillItOccur: this.$store.state.idea.ideaSettings.startingFrom
      }
      this.focus = {
        type: false,
        name: false,
        numberOfInstallments: false,
        paymentMethod: false,
        whenWillItOccur: false
      }
      this.checkInputLabels()
    },

    resetGuide () {
      if (this.$store.getters['idea/getStartupCosts'].length === 0) {
        this.guideVisible = false
      }
    },

    saveStartupCost () {
      this.loading = true
      if (this.form.id === 0) {
        this.addStartupCost()
      } else {
        this.updateStartupCost()
      }
    },

    addStartupCost () {
      this.$http.post('story-mode/set-up/startup-costs/startup-costs', this.form)
        .then((response) => {
          if (response.status === 201) {
            this.resetGuide()
            this.$store.commit('idea/addStartupCost', response.data.payload.startupCost)
            this.populateTableRowsToExpand(response.data.payload.startupCost)
            this.handleGamification()
            this.resetForm()
          }
          this.$emit('on-update-startup-cost-view')
        }).finally(() => {
          this.loading = false
        })
    },

    updateStartupCost () {
      this.$http.put(`story-mode/set-up/startup-costs/startup-costs/${this.form.id}`, this.form)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('idea/updateStartupCost', response.data.payload.startupCost)
            this.resetForm()
          }
          this.$emit('on-update-startup-cost-view')
        }).finally(() => {
          this.loading = false
        })
    },

    onEditStartupCost (startupCost) {
      this.copyStartupCostToForm(startupCost)
    },

    onCopyStartupCost (startupCost) {
      this.copyStartupCostToForm(startupCost)
      this.form.id = 0
    },

    copyStartupCostToForm (startupCost) {
      this.resetForm()
      this.form = Object.assign({}, startupCost)
      this.form.ideaId = this.$store.state.idea.id

      if (this.form.type === this.form.typeGroup) {
        this.form.typeNiceName = this.getTypeGroupNiceName(this.form.type)
      } else {
        for (let i = 0; i < this.options.groups.length; i++) {
          if (this.form.typeNiceName) {
            break
          }

          for (let j = 0; j < this.options.groups[i].items.length; j++) {
            if (this.form.type === this.options.groups[i].items[j].value) {
              this.form.typeNiceName = this.options.groups[i].items[j].label
              break
            }
          }
        }
      }
      this.checkInputLabels()
      this.scrollToTop()
    },

    onDeleteStartupCost (startupCost) {
      this.deleteStoryModeStartupCosts(startupCost.id)
        .then((response) => {
          this.$emit('on-update-startup-cost-view')
        })
    },

    checkInputLength (input) {
      this.focus[input] = !!this.form[input]
    },

    checkInputLabels () {
      this.checkInputLength('type')
      this.checkInputLength('numberOfInstallments')
      this.checkInputLength('paymentMethod')
      this.checkInputLength('name')
      this.checkInputLength('whenWillItOccur')
    },

    changeTypeGroup (value) {
      // if (this.isTypeGroupDisabled(value) === false) {
      this.form.type = value
      this.form.typeGroup = value
      const niceName = this.getTypeGroupNiceName(value)
      // this.form.name = niceName
      this.form.typeNiceName = niceName
      this.$refs.groupSelect.visible = false
      this.checkInputLabels()
      // }
    },

    changeType (value) {
      this.form.type = value
      this.form.typeGroup = ''

      for (let i = 0; i < this.options.groups.length; i++) {
        if (this.form.typeGroup) {
          break
        }

        for (let j = 0; j < this.options.groups[i].items.length; j++) {
          if (value === this.options.groups[i].items[j].value) {
            this.form.typeGroup = this.options.groups[i].value
            this.form.typeNiceName = this.options.groups[i].items[j].label
            // this.form.name = this.options.groups[i].items[j].label
            break
          }
        }
      }
      this.checkInputLabels()
    },

    getTypeGroupNiceName (value) {
      return this.options.groups.find(group => group.value === value).label
    },

    changeWhenWillItOccur () {
      this.toggleFocus('whenWillItOccur')
    },

    changeToOneTime () {
      this.form.numberOfInstallments = null
      this.form.paymentMethod = ''
      this.checkInputLength('numberOfInstallments')
    },

    changeToInstallments () {
      this.form.paymentMethod = 'monthly'
      this.checkInputLength('paymentMethod')
      setTimeout(() => {
        this.$refs.installmentsInput.$el.focus()
      }, 100)
    },

    getFormattedDate (date) {
      return this.$moment(date).format('MMM YYYY')
    },

    changePaymentMethod () {
      this.checkInputLength('numberOfInstallments')
    },

    isTypeDisabled (typeName, groupName) {
      let disabled = false
      const startupCostsCopy = [...this.$store.state.idea.storyMode.setUp.startupCosts.startupCosts]

      for (let i = 0; i < startupCostsCopy.length; i++) {
        disabled = startupCostsCopy[i].typeGroup === groupName && startupCostsCopy[i].type === groupName

        if (disabled === true) {
          break
        }
      }

      return disabled
    },

    isTypeGroupDisabled (groupName) {
      let disabled = false
      const startupCostsCopy = [...this.$store.state.idea.storyMode.setUp.startupCosts.startupCosts]

      for (let i = 0; i < startupCostsCopy.length; i++) {
        disabled = startupCostsCopy[i].typeGroup === groupName

        if (disabled === true) {
          break
        }
      }

      return disabled
    },

    populateTableRowsToExpand (startupCost) {
      const startupCosts = ['facilities', 'equipment', 'inventory', 'initial-promotion', 'miscellaneous']
      this.tableRowsToExpand.push(startupCosts.indexOf(startupCost.typeGroup) + 1)
      this.$emit('expand-table-rows', this.tableRowsToExpand)
    },

    maximumNumberOfInstallments () {
      if (this.form.paymentMethod) {
        return this.$store.state.idea.ideaSettings.planningFor * 12 / this.getNumberOfMonthsByInterval(this.form.paymentMethod)
      }

      return 1000
    },

    getNumberOfMonthsByInterval (interval) {
      const numberOfMonths = {
        monthly: 1,
        quarterly: 3,
        annually: 12
      }

      if (this.form.paymentMethod !== null) {
        return numberOfMonths[interval.toLowerCase()]
      }
    },

    async deleteExamples () {
      try {
        this.removeLoadingExamples = true
        const response = await this.deleteIdeaExamples('startup-costs')
        this.$emit('on-update-startup-cost-view')
        const { type, ids } = response
        this.deleteFromState({ type, ids })
        this.resetForm()
        this.removeLoadingExamples = false
      } catch (error) {
        this.removeLoadingExamples = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

.add-dialog-folder-icon {
  width: 80px;
  @include media-breakpoint-up($xxl) {
    width: 100px;
  }
}

.add-dialog-folder-text {
  font-size: 14px;
  @include media-breakpoint-up($xxl) {
    font-size: 16px;
  }
}

</style>
